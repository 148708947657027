import { CounterpartyProfileType, RelationType } from "../../../api/wallet/types";
import { countries } from "@/shared/constants/countries";
import lineOfBusinessDict from "@/shared/constants/lineOfBusinessDict.json";
import { ASCIIOnlyFormat2Message } from "@/shared/utils/error";
var mapEnumToKeyLabel = function (enumData) {
    return Object.keys(enumData).map(function (key) { return ({
        key: enumData[key],
        label: key
            .replace(/_/g, " ")
            .split(" ")
            .map(function (word) { return word.charAt(0) + word.slice(1).toLowerCase(); })
            .join(" "),
    }); });
};
export var RECIPIENT_FIELDS_CONFIG = [
    {
        label: "Recipient type",
        field: "profile.profileType",
        grid: "1/-1",
        type: "select",
        options: [
            {
                key: CounterpartyProfileType.CORPORATION,
                label: "Corporation",
            },
            {
                key: CounterpartyProfileType.INDIVIDUAL,
                label: "Individual",
            },
        ],
    },
    {
        label: "Name",
        field: "profile.name",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; }
        }
    },
    // {
    //     label: "Name",
    //     field: "profile.email",
    //     grid: "1/-1",
    //     type: "input",
    // },
    // {
    //     label: "Name",
    //     field: "profile.telephoneNumber",
    //     grid: "1/-1",
    //     type: "input",
    // },
    {
        label: "Country",
        field: "profile.address.country",
        grid: "span 1",
        type: "select",
        options: countries.map(function (item) { return ({
            key: item.codeAlpha3,
            label: "".concat(item.flag, " ").concat(item.name),
        }); }),
    },
    {
        label: "City",
        field: "profile.address.town",
        grid: "span 1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 21 || "Ensure length is less than or equal to 21 characters"; },
        }
    },
    {
        label: "State/Province",
        field: "profile.address.state",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; }
        }
    },
    {
        label: "Zip Code/Postal Code",
        field: "profile.address.postCode",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Address",
        field: "profile.address.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "Line of Business",
        field: "profile.lineOfBusiness",
        grid: "1/-1",
        type: "select",
        options: lineOfBusinessDict,
    },
    {
        label: "Relation to customer",
        field: "profile.relationshipToCustomer",
        grid: "1/-1",
        type: "select",
        options: mapEnumToKeyLabel(RelationType),
    },
    {
        label: "Tax Reference Number (EIN, FEIN, TIN, etc)",
        field: "profile.taxReferenceNumber",
        grid: "1/-1",
        type: "input",
    },
];
export var FedwireBankFields = [
    {
        label: "Account number",
        field: "accountNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Routing number",
        field: "routingNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "City",
        field: "institutionAddress.town",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 21 || "Ensure length is less than or equal to 21 characters"; }
        }
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; }
        }
    },
    {
        label: "Address",
        field: "institutionAddress.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "Zip Code/Postal Code",
        field: "institutionAddress.postCode",
        grid: "1/-1",
        type: "input",
    },
];
export var SepaBankFields = [
    {
        label: "IBAN",
        field: "iban",
        grid: "1/-1",
        type: "input",
        validate: {
            isIban: function (value) { return /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/.test(value) || "Incorrect IBAN"; }
        }
    },
    {
        label: "SWIFT (BIC) code",
        field: "swiftBic",
        grid: "1/-1",
        type: "input",
        validate: {
            isBic: function (value) { return /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value) || "Incorrect SWIFT/BIC"; }
        }
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; }
        }
    },
    {
        label: "City",
        field: "institutionAddress.town",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 21 || "Ensure length is less than or equal to 21 characters"; },
        }
    },
    {
        label: "Address",
        field: "institutionAddress.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "Address 2",
        field: "profile.address.subStreet",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "Zip Code/Postal Code",
        field: "institutionAddress.postCode",
        grid: "1/-1",
        type: "input",
    },
];
export var AchBankFields = [
    {
        label: "Account number",
        field: "accountNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Routing number",
        field: "routingNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; }
        }
    },
];
export var SwiftBankFields = [
    {
        label: "Account number",
        field: "accountNumber",
        grid: "1/-1",
        type: "input",
    },
    {
        label: "SWIFT (BIC) code",
        field: "swiftBic",
        grid: "1/-1",
        type: "input",
        validate: {
            isBic: function (value) { return /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value) || "Incorrect SWIFT/BIC"; }
        }
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; }
        }
    },
    {
        label: "City",
        field: "institutionAddress.town",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 21 || "Ensure length is less than or equal to 21 characters"; },
        }
    },
    {
        label: "Address",
        field: "institutionAddress.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "Address 2",
        field: "profile.address.subStreet",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "Zip Code/Postal Code",
        field: "institutionAddress.postCode",
        grid: "1/-1",
        type: "input",
    },
    {
        type: "intermediaryInfo",
    },
];
export var SwiftIbanBankFields = [
    {
        label: "IBAN",
        field: "accountNumber",
        grid: "1/-1",
        type: "input",
        validate: {
            isIban: function (value) { return /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/.test(value) || "Incorrect IBAN"; }
        }
    },
    {
        label: "SWIFT (BIC) code",
        field: "swiftBic",
        grid: "1/-1",
        type: "input",
        validate: {
            isBic: function (value) { return /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value) || "Incorrect SWIFT/BIC"; }
        }
    },
    {
        label: "Institution name",
        field: "institutionName",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "State/Province",
        field: "institutionAddress.state",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; }
        }
    },
    {
        label: "City",
        field: "institutionAddress.town",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 21 || "Ensure length is less than or equal to 21 characters"; },
        }
    },
    {
        label: "Address",
        field: "institutionAddress.street",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "Address 2",
        field: "profile.address.subStreet",
        grid: "1/-1",
        type: "input",
        validate: {
            hasAscii: function (value) { return /^[\p{ASCII}]*$/u.test(value) || ASCIIOnlyFormat2Message.description; },
            maxLength: function (value) { return value === undefined || (value === null || value === void 0 ? void 0 : value.length) <= 35 || "Ensure length is less than or equal to 35 characters"; },
        }
    },
    {
        label: "Zip Code/Postal Code",
        field: "institutionAddress.postCode",
        grid: "1/-1",
        type: "input",
    },
    {
        type: "intermediaryInfo",
    },
];
export var bankFields = {
    SWIFT_IBAN: SwiftIbanBankFields,
    SWIFT: SwiftBankFields,
    SEPA: SepaBankFields,
    ACH: AchBankFields,
    FEDWIRE: FedwireBankFields,
};
