var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useQueryClient } from "@tanstack/react-query";
import React, { useLayoutEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { bankFields, RECIPIENT_FIELDS_CONFIG } from "../../constants/fields";
import { CreateRecipientStore } from "../../store/CreateRecipientStore";
import { CounterpartyRailType, CounterpartyType } from "@/api/wallet/types";
import { useCreateRecipient } from "@/api/wallet/useCreateRecipient";
import { IntermediaryInfo } from "@/features/create-recipient/components/Form/IntermediaryInfo";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Tab, Tabs, TabsList } from "@/shared/components/Tabs";
import counterpartyCountries from "@/shared/constants/counterpartyCountries.json";
import { countries } from "@/shared/constants/countries";
import ibanCountries from "@/shared/constants/countriesIBAN.json";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
import { RequiredFieldMessage } from "@/shared/utils/error";
export var CreateRecipientForm = function () {
    var _a, _b, _c, _d, _e, _f;
    var recipientList = useStoreContext(RecipientListStore);
    var setIsOpen = useStoreContext(CreateRecipientStore).setIsOpen;
    var _g = useForm(), control = _g.control, handleSubmit = _g.handleSubmit;
    var _h = useState(null), tab = _h[0], setTab = _h[1];
    var _j = useState(CounterpartyType.FIAT), type = _j[0], setType = _j[1];
    var currencyList = useStoreContext(CurrencyListStore);
    var media = useMedia();
    var queryClient = useQueryClient();
    var _k = useCreateRecipient({
        onSuccess: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, recipientList.sync()];
                    case 1:
                        _a.sent();
                        setIsOpen(false);
                        queryClient.invalidateQueries({
                            queryKey: ["walletApi.counterparty.getCounterparties"],
                        });
                        return [2 /*return*/];
                }
            });
        }); },
    }), mutateAsync = _k.mutateAsync, isPending = _k.isPending;
    var countryCurrencies = React.useMemo(function () { return (Object.entries(counterpartyCountries)
        .map(function (_a) {
        var _b;
        var key = _a[0], value = _a[1];
        return ({
            codeAlpha2: key,
            codeAlpha3: (_b = countries.find(function (item) { return item.codeAlpha2 === key; })) === null || _b === void 0 ? void 0 : _b.codeAlpha3,
            currencies: Object.entries(value).map(function (_a) {
                var currKey = _a[0], currVal = _a[1];
                return ({
                    slug: currKey,
                    type: currVal,
                });
            }),
        });
    })
        .filter(function (item) { return !!item.codeAlpha3; })); }, []);
    var selectedCountry = useWatch({ control: control, name: "institutionAddress.country" });
    var profileType = useWatch({ control: control, name: "profile.profileType" });
    var visibleCountry = selectedCountry !== null && selectedCountry !== void 0 ? selectedCountry : "";
    var currentCountryCurrencies = countryCurrencies.find(function (item) { return item.codeAlpha3 === visibleCountry; });
    var avaliableCurrencies = currentCountryCurrencies === null || currentCountryCurrencies === void 0 ? void 0 : currentCountryCurrencies.currencies.map(function (item) { return item.slug.toLowerCase(); });
    var currencies = (_a = currencyList.list) === null || _a === void 0 ? void 0 : _a.filter(function (item) {
        return item.type === "FIAT" && item.slug && avaliableCurrencies
            && avaliableCurrencies.includes(item.slug.toLowerCase());
    });
    var countryAlpha2 = (_b = countries.find(function (item) { return item.codeAlpha3 === selectedCountry; })) === null || _b === void 0 ? void 0 : _b.codeAlpha2;
    var isIbanCountry = !!ibanCountries.find(function (item) { return item === countryAlpha2; });
    var blockchain = useWatch({ control: control, name: "blockchain" });
    var selectedCurrency = useWatch({ control: control, name: "currency" });
    var visibleCurrency = selectedCurrency;
    var paymentTypes = currentCountryCurrencies
        ? (_c = currentCountryCurrencies.currencies.find(function (item) { return item.slug.toLowerCase() === (visibleCurrency === null || visibleCurrency === void 0 ? void 0 : visibleCurrency.toLowerCase()); })) === null || _c === void 0 ? void 0 : _c.type
        : undefined;
    var blockchainList = selectedCurrency
        ? (_f = ((_e = (_d = currencyList.bySlug[selectedCurrency]) === null || _d === void 0 ? void 0 : _d.tokens) === null || _e === void 0 ? void 0 : _e.filter(function (item) { return item.blockchain; }).map(function (item) { return item.blockchain; }))) !== null && _f !== void 0 ? _f : []
        : [];
    var selectedTab = tab !== null && tab !== void 0 ? tab : paymentTypes === null || paymentTypes === void 0 ? void 0 : paymentTypes.at(0);
    var recipientFields = RECIPIENT_FIELDS_CONFIG;
    var bankAccountFields = selectedTab ? bankFields[selectedTab] : [];
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (type === CounterpartyType.CRYPTO) {
                mutateAsync(__assign(__assign({}, data), { type: CounterpartyType.CRYPTO, rail: CounterpartyRailType.CRYPTO, disabled: false }));
            }
            else {
                mutateAsync(__assign(__assign({}, data), { type: type, rail: selectedTab !== "SWIFT_IBAN" ? selectedTab : "SWIFT", disabled: false }));
            }
            return [2 /*return*/];
        });
    }); };
    useLayoutEffect(function () {
        setTab(null);
    }, [selectedCountry]);
    useLayoutEffect(function () {
        setTab(null);
    }, [selectedCountry, selectedCurrency]);
    useLayoutEffect(function () {
        setType(CounterpartyType.FIAT);
    }, []);
    return (React.createElement(Box, { width: "100%", mb: media.tablet ? "40px" : "100px" },
        React.createElement(Box, { display: "grid", gridTemplateColumns: media.tablet ? "1fr" : "1fr 1fr", gap: "40px" },
            React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Enter recipient's details"), recipientFields === null || recipientFields === void 0 ? void 0 :
            recipientFields.map(function (item) {
                var field = null;
                var isProfileFieldHidden = profileType === "INDIVIDUAL" && item.field === "profile.lineOfBusiness";
                if (isProfileFieldHidden)
                    return null;
                if (item.type === "input") {
                    var isRequired_1 = item.field !== "profile.address.subStreet" && item.field !== "profile.taxReferenceNumber";
                    field = (React.createElement(Controller, { rules: {
                            required: isRequired_1
                                ? {
                                    value: true,
                                    message: RequiredFieldMessage.description,
                                } : undefined,
                            // pattern: item.regex,
                            validate: item.validate
                        }, control: control, name: item.field, render: function (_a) {
                            var field = _a.field, error = _a.fieldState.error;
                            return (React.createElement(FormControlInput, __assign({ disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, label: item.label, InputProps: !isRequired_1 ? {
                                    endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                                } : undefined }, field)));
                        } }));
                }
                if (item.type === "select") {
                    field = (React.createElement(Controller, { rules: {
                            required: {
                                value: true,
                                message: RequiredFieldMessage.description,
                            },
                        }, control: control, name: item.field, render: function (_a) {
                            var _b;
                            var field = _a.field, error = _a.fieldState.error;
                            return (React.createElement(FormControlSelect, __assign({}, field, { disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, options: (_b = item.options) !== null && _b !== void 0 ? _b : [], label: item.label })));
                        } }));
                }
                return React.createElement(Box, { key: item.label, gridColumn: item.grid }, field);
            })),
        React.createElement(Box, { mt: "60px", mb: "60px", width: "100%", height: "1px", bgColor: "black-100" }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
            React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Select recipient type"),
            React.createElement(FormControlSelect, { value: type, options: [
                    {
                        key: CounterpartyType.FIAT,
                        label: "Fiat",
                    },
                    {
                        key: CounterpartyType.CRYPTO,
                        label: "Crypto",
                    },
                ], disabled: isPending, onChange: function (_, v) {
                    setType(v
                        ? v.key
                        : CounterpartyType.FIAT);
                }, label: "Recipient type" })),
        React.createElement(Box, { mt: "60px", mb: "60px", width: "100%", height: "1px", bgColor: "black-100" }),
        type === CounterpartyType.CRYPTO
            ? (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
                React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Enter blockchain details"),
                React.createElement(Controller, { control: control, rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, name: "currency", render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlSelect, __assign({}, field, { options: currencyList.list
                                .filter(function (item) { return item.type === "CRYPTO"; })
                                .map(function (item) { return ({
                                key: item.slug.toLowerCase(),
                                label: item.shortName.toUpperCase(),
                            }); }), disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, onChange: function (_, v) {
                                field.onChange(v === null || v === void 0 ? void 0 : v.key);
                            }, label: "Currency" })));
                    } }),
                React.createElement(Controller, { control: control, rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, name: "blockchain", render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlSelect, __assign({}, field, { options: blockchainList.map(function (item) { return ({
                                key: item.slug.toLowerCase(),
                                label: item.name.toUpperCase(),
                            }); }), disabled: isPending || !selectedCurrency, error: error === null || error === void 0 ? void 0 : error.message, onChange: function (_, v) {
                                field.onChange(v === null || v === void 0 ? void 0 : v.key);
                            }, label: "Blockchain" })));
                    } }),
                React.createElement(Controller, { rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, control: control, name: "blockchainAddress", render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlInput, __assign({ disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, label: "Blockchain address" }, field)));
                    } }),
                React.createElement(Controller, { control: control, rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, name: "walletType", render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlSelect, __assign({}, field, { options: [
                                {
                                    key: "INSTITUTION",
                                    label: "Institution",
                                },
                                {
                                    key: "OTHER",
                                    label: "Other",
                                },
                                {
                                    key: "UNKNOWN",
                                    label: "Unknown",
                                },
                            ], disabled: isPending || !blockchain, error: error === null || error === void 0 ? void 0 : error.message, onChange: function (_, v) {
                                field.onChange(v === null || v === void 0 ? void 0 : v.key);
                            }, label: "Wallet type" })));
                    } }),
                React.createElement(Controller, { rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, control: control, name: "institutionName", render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlInput, __assign({ disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, label: "Institution name" }, field)));
                    } }),
                React.createElement(Controller, { control: control, name: "description", render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlInput, __assign({ disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, label: "Description" }, field)));
                    } })))
            : (React.createElement(React.Fragment, null,
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
                    React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Select bank account country"),
                    React.createElement(Controller, { control: control, rules: {
                            required: {
                                value: true,
                                message: RequiredFieldMessage.description,
                            },
                        }, name: "institutionAddress.country", render: function (_a) {
                            var field = _a.field, error = _a.fieldState.error;
                            return (React.createElement(FormControlSelect, __assign({}, field, { options: countries
                                    .filter(function (item) {
                                    return Object.keys(counterpartyCountries).includes(item.codeAlpha2);
                                })
                                    .map(function (item) { return ({
                                    key: item.codeAlpha3,
                                    label: "".concat(item.flag, " ").concat(item.name),
                                }); }), disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, onChange: function (_, v) {
                                    field.onChange(v === null || v === void 0 ? void 0 : v.key);
                                }, label: "Country" })));
                        } }),
                    React.createElement(Controller, { rules: {
                            required: {
                                value: true,
                                message: RequiredFieldMessage.description,
                            },
                        }, control: control, name: "currency", render: function (_a) {
                            var field = _a.field, error = _a.fieldState.error;
                            return (React.createElement(FormControlSelect, __assign({}, field, { error: error === null || error === void 0 ? void 0 : error.message, disabled: isPending || !selectedCountry, options: (currencies === null || currencies === void 0 ? void 0 : currencies.map(function (item) {
                                    var _a;
                                    return ({
                                        key: item.slug,
                                        label: (_a = item.slug) === null || _a === void 0 ? void 0 : _a.toUpperCase(),
                                    });
                                })) || [], onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, label: "Currency" })));
                        } })),
                React.createElement(Box, { mt: "60px", mb: "60px", width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr 1fr", gap: "40px" }, paymentTypes ?
                    React.createElement(React.Fragment, null,
                        React.createElement(Typography, { type: "text32Bold", gridColumn: "1/-1" }, "Enter bank account details"),
                        React.createElement(Box, null,
                            React.createElement(Tabs, { value: selectedTab, onChange: function (_, value) { return setTab(value); } },
                                React.createElement(TabsList, null, paymentTypes === null || paymentTypes === void 0 ? void 0 : paymentTypes.map(function (item) { return (React.createElement(Tab, { value: item, key: item }, (function () {
                                    switch (item) {
                                        case "SWIFT_IBAN":
                                            return "SWIFT";
                                        case "FEDWIRE":
                                            return "Fedwire";
                                        default:
                                            return item;
                                    }
                                })())); })))),
                        bankAccountFields.map(function (item) {
                            var field = null;
                            if (item.type === "input") {
                                var isRequired = item.field !== "profile.address.subStreet";
                                var isAccountNumber = item.field === "accountNumber";
                                var label_1 = isAccountNumber && isIbanCountry && tab === CounterpartyRailType.SWIFT
                                    ? "IBAN"
                                    : item.label;
                                field = (React.createElement(Controller, { rules: {
                                        required: isRequired ? {
                                            value: true,
                                            message: RequiredFieldMessage.description,
                                        } : undefined,
                                        validate: "validate" in item ? item.validate : undefined
                                    }, control: control, name: item.field, render: function (_a) {
                                        var field = _a.field, error = _a.fieldState.error;
                                        return (React.createElement(FormControlInput, __assign({ disabled: isPending, error: error === null || error === void 0 ? void 0 : error.message, label: label_1 }, field)));
                                    } }));
                                return React.createElement(Box, { key: item.label, gridColumn: item.grid }, field);
                            }
                            if (item.type === "intermediaryInfo") {
                                return (React.createElement(IntermediaryInfo, { key: "intermediaryInfo", control: control }));
                            }
                            return null;
                        }))
                    :
                        null))),
        React.createElement(Button, { width: "250px", mt: "100px", onClick: handleSubmit(onSubmit), disabled: isPending }, "Save")));
};
