var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Controller } from "react-hook-form";
import { Box, Checkbox, FormControlInput, Typography } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { countries } from "@/shared/constants/countries";
import { RequiredFieldMessage } from "@/shared/utils/error";
export var IntermediaryInfo = function (_a) {
    var control = _a.control;
    var _b = React.useState(false), enabled = _b[0], setEnabled = _b[1];
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px", gridColumn: "1/-1" },
        React.createElement(CheckboxPanel, { key: "intermediaryInfo", onClick: function () { return setEnabled(!enabled); } },
            React.createElement(Typography, { type: "text16", color: "black-500" }, "Add intermediary bank information"),
            React.createElement(Checkbox, { size: "medium", checked: enabled })),
        enabled && (React.createElement(React.Fragment, null,
            React.createElement(Controller, { rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, control: control, name: "intermediary.institutionAddress.country", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: countries.map(function (item) { return ({
                            key: item.codeAlpha3,
                            label: "".concat(item.flag, " ").concat(item.name),
                        }); }), label: "Country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } }),
            React.createElement(Controller, { rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, control: control, name: "intermediary.institutionName", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({ error: error === null || error === void 0 ? void 0 : error.message, label: "Intermediary institution / Correspondent bank" }, field)));
                } }),
            React.createElement(Controller, { rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, control: control, name: "intermediary.swiftBic", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({ error: error === null || error === void 0 ? void 0 : error.message, label: "SWIFT / BIC" }, field)));
                } })))));
};
var CheckboxPanel = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    background: ", ";\n    padding: 30px;\n    justify-content: space-between;\n    align-items: center;\n    border-radius: 30px;\n    cursor: pointer;\n"], ["\n    display: flex;\n    background: ", ";\n    padding: 30px;\n    justify-content: space-between;\n    align-items: center;\n    border-radius: 30px;\n    cursor: pointer;\n"])), function (props) { return props.theme.colors["black-50"]; });
var templateObject_1;
