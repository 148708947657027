export var PARTNER_ID = "1";
export var CLIENT_ID = "gooseethahPeecied8Eegu4xeeseewuh";
export var ACQUIRING_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app/acquiring";
export var APPROVER_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app";
export var CARD_HOLDER_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app";
export var CURRENCY_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app/currency";
export var REGISTRATION_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app/reg";
export var WALLET_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app/wallet";
export var STAND_URL = "https://vault.stg.darknet.piefi.app";
export var ZEN_DESK_ID = "b1a87a80-c4a1-4b63-9cb8-bedbe6d4be3c";
export var ZEN_DESK_NAME = "Vbanq";
export var EMAIL_HELP = "";
export var EMAIL_HELLO = "";
